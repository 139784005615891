import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";
import { QueryStatus, axiosInstanceWithPhoneToken } from "../../utils";
import { UpdateDriverPhone } from "./updateDriverPhoneApi";

export interface updateDriverPhoneState {
  updateDriverPhoneStatus: QueryStatus;
}

const initialState: updateDriverPhoneState = {
  updateDriverPhoneStatus: "idle",
};

export const updateDriverPhoneAsync = createAsyncThunk(
  "updateDriverPhoneAsync/call",
  async (payload: UpdateDriverPhone) => {
    const phoneToken = localStorage.getItem("phoneToken");
    if (!phoneToken) {
      throw Error();
    }
    const axios = axiosInstanceWithPhoneToken(phoneToken);
    await axios.post("driver_access/driver/update_phone", payload);
  },
);

export const updateDriverPhoneSlice = createSlice({
  name: "update_driver_phone",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateDriverPhoneAsync.pending, (state) => {
        state.updateDriverPhoneStatus = "processing";
      })
      .addCase(updateDriverPhoneAsync.fulfilled, (state) => {
        state.updateDriverPhoneStatus = "success";
      })
      .addCase(updateDriverPhoneAsync.rejected, (state) => {
        state.updateDriverPhoneStatus = "failed";
      });
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectUpdateDriverPhoneStatus = (state: RootState) =>
  state.updateDriverPhone.updateDriverPhoneStatus;

export default updateDriverPhoneSlice.reducer;
