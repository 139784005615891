import {
  Button,
  Input,
  Spacer,
  TextCapitalized,
  Checkbox,
  ButtonDS,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import styled from "styled-components";
import { Page } from "../components/Page";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

type DriverNameFormType = "CREATION" | "UPDATE";

export const DriverNameForm = (props: {
  phone: string;
  firstName: string;
  lastName: string;
  setFirstName: (value: string) => void;
  setLastName: (value: string) => void;
  followingPage: () => void;
  termsAndPolicySentence: JSX.Element;
  type: DriverNameFormType;
}) => {
  const { firstName, lastName, followingPage, type } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const isFormInvalid = useMemo(() => {
    return firstName === "" || lastName === "" || !isChecked;
  }, [firstName, lastName, isChecked]);

  const clickToSubmit = useCallback(() => {
    if (isFormInvalid) {
      setDisplayError(true);
    } else {
      setDisplayError(false);
      followingPage();
    }
  }, [isFormInvalid, followingPage]);

  const bottomButtonCreation = () => {
    return (
      <Button
        title={t("driverLogin.button.next")}
        onClick={clickToSubmit}
        height={3.5}
        fontSize={1.1}
        disabled={isFormInvalid}
      />
    );
  };

  const bottomButtonUpdate = () => {
    return (
      <StyledContainer>
        <ButtonDS
          text={t("driverLogin.button.register")}
          onClick={clickToSubmit}
          disabled={isFormInvalid}
          buttonType="primary"
          format="fill"
        />
        <Spacer y={1} />
        <ButtonDS
          text={t("driverLogin.button.return")}
          onClick={() => navigate("/menu")}
          buttonType="secondary"
          format="fill"
        />
      </StyledContainer>
    );
  };

  return (
    <Page
      title={t(`driverLogin.name.${type}.title`)}
      bottomButton={
        type === "CREATION" ? bottomButtonCreation() : bottomButtonUpdate()
      }
      helpButton
    >
      <StyledContainer>
        {type === "CREATION" && (
          <>
            <Spacer y={1} />
            <StyledBody>
              <Spacer x={0.25} />
              <TextCapitalized>{t("driverLogin.body") || ""}</TextCapitalized>
              <Spacer x={0.25} />
            </StyledBody>
          </>
        )}
        <Spacer y={2} />
        <Input
          label={t("driverLogin.name.label.firstName") || ""}
          placeholder={t("driverLogin.name.placeholder.firstName") || ""}
          defaultValue={props.firstName}
          onChange={(e) => props.setFirstName(e.target.value)}
          error={
            (displayError &&
              props.firstName === "" &&
              t("driverLogin.error.input")) ||
            undefined
          }
          onKeyDown={(e) => e.key === "Enter" && clickToSubmit()}
        />
        <Input
          label={t("driverLogin.name.label.lastName") || ""}
          placeholder={t("driverLogin.name.placeholder.lastName") || ""}
          defaultValue={props.lastName}
          onChange={(e) => props.setLastName(e.target.value)}
          error={
            (displayError &&
              props.lastName === "" &&
              t("driverLogin.error.input")) ||
            undefined
          }
          onKeyDown={(e) => e.key === "Enter" && clickToSubmit()}
        />
        <StyledBottom>
          <Spacer x={0.5} />
          <Checkbox
            borderColor={
              isChecked ? "transparent" : colors["colors/text/darkGrey"]
            }
            size={1}
            text={props.termsAndPolicySentence}
            check={isChecked}
            onClick={() => setIsChecked(!isChecked)}
          />
          <Spacer x={0.5} />
        </StyledBottom>
        {displayError && !isChecked && (
          <StyledErrorMessage>
            <Spacer y={1} />
            <TextCapitalized>
              {t("driverLogin.termsAndPolicy.error")}
            </TextCapitalized>
          </StyledErrorMessage>
        )}
        <Spacer y={2} />
        {type === "UPDATE" && <Spacer y={3} />}
      </StyledContainer>
    </Page>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const StyledBody = styled.div`
  display: flex;
  width: 100%;
  color: ${colors["colors/text/black"]};
`;

const StyledBottom = styled.div`
  display: flex;
  align-items: flex-end;
  height: 100%;
`;

const StyledErrorMessage = styled.div`
  display: flex;
  flex-direction: column;
  color: ${colors["colors/system/error/error_normal"]};
`;
